import PropTypes from 'prop-types'
import './NumberInput.scss'
import Icon from '../Icon/Icon.js'

const NumberInput = ({ value, onChange, min, max, ...props }) => {
  function handleInputChage (e) {
    onChange(Math.max(Math.min(~~e.target.value, max), min))
  }

  function handleAdd (delta) {
    return () => onChange(value + delta)
  }

  function isUpDisabled (value, limit) {
    if (typeof limit !== 'number') return false
    return Math.min(value, limit) === limit
  }

  function isDownDisabled (value, limit) {
    if (typeof limit !== 'number') return false
    return Math.max(value, limit) === limit
  }

  return (
    <div className="NumberInput">
      <input value={value + ''} onChange={handleInputChage} {...props} />
      <div className="buttons">
        <button onClick={handleAdd(1)} disabled={isUpDisabled(value, max)}>
          <Icon name="add" width={12} height={12} fill="var(--grey-shade-6)" />
        </button>
        <button onClick={handleAdd(-1)} disabled={isDownDisabled(value, min)}>
          <Icon name="subtract" width={12} height={12} fill="var(--grey-shade-6)" />
        </button>
      </div>
    </div>
  )
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number
}

export default NumberInput

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function downloadSubmittedAccountsData ({ startDate, endDate }) {
  return handleRequest(
    getApiHost() + '/exports/downloadSubmittedAccountsData',
    {
      method: 'GET',
      searchParams: {
        from: startDate,
        to: endDate
      },
      headers: {
        authorization: window.localStorage.getItem('adminToken') || '',
        'admin-email': window.localStorage.getItem('adminEmail') || ''
      }
    }
  )
}

export default downloadSubmittedAccountsData

import { useState } from 'react'
import Icon from '../../../components/Icon/Icon.js'
import './DeleteContactForm.scss'
import Button from '../../../components/Button/Button.js'
import api from '../../../api/api.js'
import { createErrorNotification } from '../../../components/Notifier/Notifier.js'
import Spinner from '../../../components/Spinner/Spinner.js'

const DeleteContactForm = ({ formattedContact, closeModal, removeContact }) => {
  const [loading, setLoading] = useState(false)

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await api.contacts.severContact(formattedContact.contact._id)
      if (response.success) {
        closeModal()
        return removeContact(formattedContact.contact._id)
      }
    } catch (error) {
      return createErrorNotification(error)
    }
  }

  const fullName = [formattedContact.contact.FIRSTNAME, formattedContact.contact.LASTNAME].join(' ').trim()

  return (
    <div className="DeleteContactForm">
      <div className="DeleteContactForm-head">
        <h3>{`${fullName} verwijderen?`}</h3>
        <button onClick={closeModal} className="close modal-close-button"><Icon name="cross" /></button>
      </div>
      <form onSubmit={handleSubmit}>
        <p>Dit kan niet ongedaan gemaakt worden.</p>
        <div className={`buttons ${loading ? 'loading' : ''}`}>
          <Button disabled={loading} buttonType="ghost" type="button" onClick={closeModal}>Annuleren</Button>
          <Button disabled={loading} type="submit">{ loading ? <Spinner width={20} height={20} stroke="var(--white)" /> : 'Ja, verwijderen' }</Button>
        </div>
      </form>
    </div>
  )
}

export default DeleteContactForm

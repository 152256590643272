import { useState } from 'react'
import Input from '../../../components/Input/Input.js'
import './EditAccountForm.scss'
import Icon from '../../../components/Icon/Icon.js'
import Button from '../../../components/Button/Button.js'
import api from '../../../api/api.js'
import { createErrorNotification } from '../../../components/Notifier/Notifier.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import isValidEmail from '../../../utils/isValidEmail.js'
import useHasUnmounted from '../../../hooks/useHasUnmounted.js'

const EditAccountForm = ({ email: initialEmail = '', closeModal, reloadAccount, label, updateField }) => {
  const [email, setEmail] = useState(initialEmail)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const hasUnmounted = useHasUnmounted(false)

  function handleEmailChange (e) {
    setHasError(false)
    setEmail(e.target.value)
  }

  async function handleSubmit (e) {
    e.preventDefault()
    if (!email) return setHasError(true)
    if (email === initialEmail) return closeModal()
    if (loading) return
    if (!isValidEmail(email)) return setHasError(true)
    try {
      setLoading(true)
      const data = { }
      data[updateField] = email
      const response = await api.accounts.updateInformation(data)
      if (response.success) {
        closeModal()
        return reloadAccount()
      }
    } catch (error) {
      if (!hasUnmounted.current) {
        setLoading(false)
      }
      return createErrorNotification(error)
    }
  }

  return (
    <div className="EditAccountForm">
      <div className="head">
        <h3>E-mailadres aanpassen</h3>
        <button onClick={closeModal} className="close modal-close-button"><Icon name="cross" /></button>
      </div>
      <form onSubmit={handleSubmit}>
        <Input value={email} onChange={handleEmailChange} label={label} hasError={hasError} errorMessage="E-mail is niet geldig" type="email" />
        <div className={`buttons ${loading ? 'loading' : ''}`}>
          <Button disabled={loading} buttonType="ghost" type="button" onClick={closeModal}>Annuleren</Button>
          <Button disabled={loading} type="submit">{ loading ? <Spinner width={20} height={20} stroke="var(--white)" /> : 'Opslaan' }</Button>
        </div>
      </form>
    </div>
  )
}

export default EditAccountForm

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function getRoles () {
  return handleRequest(
    getApiHost() + '/contacts/getRoles',
    {
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      }
    }
  ).json()
}

export default getRoles

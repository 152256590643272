import './Spinner.scss'

const Spinner = props => {
  return (
    <div className="Spinner">
      <svg stroke="var(--grey-shade-6)" viewBox="0 0 200 200" width="1em" height="1em" {...props}>
        <circle cx="100" cy="100" r="90" fill="none" strokeDasharray="10 53" strokeWidth="20" strokeLinecap="round"></circle>
      </svg>
    </div>
  )
}

export default Spinner

import { useRef } from 'react'
import './RadioInput.scss'

const RadioInput = ({ value, label, name, checked, onChange, hasError }) => {
  const inputRef = useRef(null)

  function handleRadioClick () {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  function handleKeyPress (e) {
    if (e.code === 'Space') {
      e.preventDefault()
      inputRef.current.click()
    }
  }

  return (
    <div className={`RadioInput ${checked ? 'RadioInput-active' : ''} ${hasError ? 'RadioInput-error' : ''}`} onClick={handleRadioClick} tabIndex="0" onKeyPress={handleKeyPress}>
      <input ref={inputRef} type="radio" name={name} value={value} checked={checked} onChange={onChange} />
      <div className="RadioInput-circle"><div className="RadioInput-outline-circle"></div></div>
      <p>{label}</p>
    </div>
  )
}

export default RadioInput

import { useState, useEffect, useRef, useCallback } from 'react'
import './EditForm.scss'
import Button from '../../../components/Button/Button.js'
import Input from '../../../components/Input/Input.js'
import Checkbox from '../../../components/Checkbox/Checkbox.js'
import useRoles from '../../../hooks/useRoles.js'
import Segment from './Segment.js'
import Icon from '../../../components/Icon/Icon.js'
import api from '../../../api/api.js'
import useHandleErrors from '../../../hooks/useHandleErrors.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import RadioInput from '../../../components/RadioInput/RadioInput.js'
import Modal from '../../../components/Modal/Modal.js'
import isValidEmail from '../../../utils/isValidEmail.js'
import validateRoles from '../../../utils/validateRoles.js'

const EditForm = ({ formattedContact: initialFormattedContact = { contact: {} }, onCancel, handleSubmitSuccess, initialSurname, editingExisting }) => {
  const [rolesError, rolesLoading, , rolesMap] = useRoles([])
  const [loading, setLoading] = useState(false)
  const [missingInput, setMissingInput] = useState(new Set())
  const [missingRole, setMissingRole] = useState(false)
  const [missingSegment, setMissingSegment] = useState(new Set())
  const [formValues, setFormValues] = useState({
    title: '',
    firstname: '',
    surname: initialSurname || '',
    email: '',
    personnelNumber: '',
    groupedRoles: new Map()
  })
  const [contactError, setContactError] = useState(null)
  const [confirmSurnameModal, setConfirmSurnameModal] = useState(false)
  const inputRef = useRef(null)
  const rolesRef = useRef(null)
  const rootRef = useRef(null)
  const initialValidationDone = useRef(false)
  useHandleErrors([rolesError, contactError])

  const validateValues = useCallback((values = formValues) => {
    const requiredInputs = ['title', 'firstname', 'surname', 'email']

    let hasAtLeastOneError = false
    let firstEncounteredError = null

    const missing = new Set(requiredInputs.filter(key => !values[key]))
    if (missing.size) {
      hasAtLeastOneError = true
      firstEncounteredError = 'input'
      setMissingInput(missing)
    }

    if (!isValidEmail(values.email)) {
      missing.add('email')
      hasAtLeastOneError = true
      firstEncounteredError = 'input'
      setMissingInput(missing)
    }

    if (values.personnelNumber) {
      const number = values.personnelNumber
      // const pattern = '^' + // start of the personnelNumber
      //   '(?:[12])' + // 1 for male or 2 for female
      //   '(?:[0-9]{2})' + // year of birth
      //   '(?:0[1-9]|1[012])' + // month of birth
      //   '(?:[012][0-9]|3[01])' + // date of birth
      //   '(?:[0-9]{2})' + // sequence number for shared birthdays
      //   '(?:[0-9]{2})' + // checksum (previous number as an int modulo 97 should match this number)
      //   '$' // end of the personnelNumber
      const pattern = '^[0-9]{11}$'
      const regex = new RegExp(pattern)
      // const sequence = parseInt(number.slice(0, -2))
      // const checksum = parseInt(number.slice(-2))
      // const checksumIsValid = (sequence % 97) === checksum
      if (!(regex.test(number) /* && checksumIsValid */)) {
        missing.add('personnelNumber')
        hasAtLeastOneError = true
        firstEncounteredError = 'input'
        setMissingInput(missing)
      }
    }

    const { hasAtLeastOneRole, rolesWithErrors } = validateRoles(values.groupedRoles)

    if (!hasAtLeastOneRole) {
      hasAtLeastOneError = true
      firstEncounteredError = firstEncounteredError || 'roles'
      setMissingRole(true)
    }

    if (rolesWithErrors.size) {
      hasAtLeastOneError = true
      firstEncounteredError = firstEncounteredError || 'segments'
      setMissingSegment(rolesWithErrors)
    }

    if (firstEncounteredError) {
      switch (firstEncounteredError) {
        case 'input': {
          inputRef.current && inputRef.current.scrollIntoView({ behavior: 'smooth' })
          break
        }
        case 'roles': {
          rolesRef.current && rolesRef.current.scrollIntoView({ behavior: 'smooth' })
          break
        }
        case 'segments': {
          if (rootRef.current) {
            const targetEl = rootRef.current.querySelector(`#segment-${[...rolesWithErrors][0]}`)
            targetEl && targetEl.scrollIntoView({ behavior: 'smooth' })
          }
          break
        }
        default:
      }
    }

    return !hasAtLeastOneError
  }, [formValues])

  useEffect(() => {
    const newFormValues = {
      title: initialFormattedContact.contact.SALUTATION || '',
      firstname: initialFormattedContact.contact.FIRSTNAME || '',
      surname: initialFormattedContact.contact.LASTNAME || '',
      email: initialFormattedContact.contact.EMAIL || '',
      personnelNumber: initialFormattedContact.contact.PERSONNEL_NUMBER__C || '',
      groupedRoles: initialFormattedContact.groupedRoles
    }
    if (!initialValidationDone.current && editingExisting && initialFormattedContact && initialFormattedContact.contact) {
      setFormValues(newFormValues)
      initialValidationDone.current = true
      validateValues(newFormValues)
    }
  }, [editingExisting, initialFormattedContact, validateValues])

  async function handleSubmit (e) {
    e.preventDefault()

    const isValid = validateValues()

    if (!isValid) {
      return
    }

    const requestBody = {
      ...(editingExisting && { contactObjectID: initialFormattedContact.contact._id }),
      data: {
        SALUTATION: formValues.title,
        GENDER__C: formValues.title === 'Mr.' ? 'Male' : 'Female',
        FIRSTNAME: formValues.firstname,
        LASTNAME: formValues.surname,
        EMAIL: formValues.email,
        PERSONNEL_NUMBER__C: formValues.personnelNumber || ''
      },
      acr: []
    }

    for (const [, value] of formValues.groupedRoles) {
      const roles = []
      if (value.segments.length) {
        for (const segment of value.segments) {
          roles.push({
            ...(segment._id && { _id: segment._id }),
            ROLE__C: value.role.ROLE__C || value.role.ID,
            SEGMENT__C: segment.SEGMENT__C,
            YEAR1PRESENT__C: segment.YEAR1PRESENT__C || '0',
            YEAR2PRESENT__C: segment.YEAR2PRESENT__C || '0',
            YEAR3PRESENT__C: segment.YEAR3PRESENT__C || '0',
            YEAR4PRESENT__C: segment.YEAR4PRESENT__C || '0',
            ...(segment.YEAR5PRESENT__C && { YEAR5PRESENT__C: segment.YEAR5PRESENT__C || '0' }),
            ...(segment.YEAR6PRESENT__C && { YEAR6PRESENT__C: segment.YEAR6PRESENT__C || '0' })
          })
        }
      } else {
        roles.push({
          ...(value.role._id && { _id: value.role._id }),
          ROLE__C: value.role.ROLE__C || value.role.ID,
          SEGMENT__C: '',
          YEAR1PRESENT__C: '0',
          YEAR2PRESENT__C: '0',
          YEAR3PRESENT__C: '0',
          YEAR4PRESENT__C: '0',
          YEAR5PRESENT__C: '0',
          YEAR6PRESENT__C: '0'
        })
      }
      requestBody.acr = [...requestBody.acr, ...roles]
    }

    try {
      setLoading(true)
      setContactError(null)
      if (editingExisting) {
        await api.contacts.updateContact(requestBody)
        return handleSubmitSuccess(initialFormattedContact.contact._id)
      } else {
        const { contactObjectID } = await api.contacts.createContact(requestBody)
        return handleSubmitSuccess(contactObjectID)
      }
    } catch (error) {
      setLoading(false)
      return setContactError(error)
    }
  }

  function handleInputChange (name) {
    return e => {
      if (missingInput.has(name)) {
        const newSet = new Set(missingInput)
        newSet.delete(name)
        setMissingInput(newSet)
      }
      setFormValues({
        ...formValues,
        [name]: e.target.value
      })
    }
  }

  function handleRoleChange (role) {
    return (e) => {
      if (!role) return
      if (missingRole) {
        setMissingRole(false)
      }
      const checked = e.target.checked
      const newRoles = new Map(formValues.groupedRoles)
      if (checked) {
        newRoles.set(role.ID, {
          role,
          segments: []
        })
      } else {
        newRoles.delete(role.ID)
      }
      setFormValues({
        ...formValues,
        groupedRoles: newRoles
      })
    }
  }

  function handleSegmentChange (roleCode) {
    return segmentCode => {
      return e => {
        if (missingSegment.has(roleCode)) {
          const newSet = new Set(missingSegment)
          newSet.delete(roleCode)
          setMissingSegment(newSet)
        }
        const thisRole = formValues.groupedRoles.get(roleCode)
        let updatedSegments
        if (e.target.checked) {
          updatedSegments = [...thisRole.segments, {
            SEGMENT__C: segmentCode,
            SEGMENTCODE__C: segmentCode,
            YEAR1PRESENT__C: '0',
            YEAR2PRESENT__C: '0',
            YEAR3PRESENT__C: '0',
            YEAR4PRESENT__C: '0',
            ...(!new Set(['KO', 'BKO']).has(segmentCode) && {
              YEAR5PRESENT__C: '0',
              YEAR6PRESENT__C: '0'
            })
          }]
        } else {
          updatedSegments = thisRole.segments.filter(({ SEGMENTCODE__C }) => SEGMENTCODE__C !== segmentCode)
        }
        const newRoles = new Map(formValues.groupedRoles)
        newRoles.set(roleCode, {
          ...thisRole,
          segments: updatedSegments
        })
        setFormValues({
          ...formValues,
          groupedRoles: newRoles
        })
      }
    }
  }

  function handleSegmentYearChange (roleCode) {
    return segmentCode => {
      return segmentYear => {
        return e => {
          if (missingSegment.has(roleCode)) {
            const newSet = new Set(missingSegment)
            newSet.delete(roleCode)
            setMissingSegment(newSet)
          }
          const thisRole = formValues.groupedRoles.get(roleCode)
          const updatedSegments = thisRole.segments.map(segment => {
            if (segment.SEGMENTCODE__C === segmentCode) {
              return {
                ...segment,
                [segmentYear]: e.target.checked ? '1' : '0'
              }
            } else return segment
          })
          const newRoles = new Map(formValues.groupedRoles)
          newRoles.set(roleCode, {
            ...thisRole,
            segments: updatedSegments
          })
          setFormValues({
            ...formValues,
            groupedRoles: newRoles
          })
        }
      }
    }
  }

  function createCheckbox (ROLE__C) {
    const role = rolesMap.get(ROLE__C)
    if (!role) return null
    let disabled = false
    if (ROLE__C === 'a0d24000000TibXAAS' && formValues.groupedRoles.size && !formValues.groupedRoles.has('a0d24000000TibXAAS')) {
      disabled = true
    } else if (ROLE__C !== 'a0d24000000TibXAAS' && formValues.groupedRoles.size && formValues.groupedRoles.has('a0d24000000TibXAAS')) {
      disabled = true
    }
    return <Checkbox hasError={missingRole} label={role.Frontend} checked={!!formValues.groupedRoles.get(ROLE__C)} onChange={handleRoleChange(role)} disabled={disabled} />
  }

  function createSegment (ROLE__C) {
    const role = rolesMap.get(ROLE__C)
    if (!role) return null
    return <Segment
      hasError={missingSegment.has(ROLE__C)}
      roleCode={role.ID}
      roleName={role.Frontend}
      active={!!formValues.groupedRoles.get(ROLE__C)}
      onChange={handleSegmentChange(ROLE__C)}
      onYearChange={handleSegmentYearChange(ROLE__C)}
      value={formValues.groupedRoles.get(ROLE__C)}
    />
  }

  function verifySurnameChange () {
    if (initialFormattedContact.contact.LASTNAME && formValues.surname !== initialFormattedContact.contact.LASTNAME) {
      setConfirmSurnameModal(true)
    }
  }

  function handleConfirmSurname (accept) {
    if (!accept) {
      setFormValues({
        ...formValues,
        surname: initialFormattedContact.contact.LASTNAME
      })
    }
    setConfirmSurnameModal(false)
  }

  const buttonName = editingExisting ? 'Aanpassen' : 'Toevoegen'

  return (
    <form onSubmit={handleSubmit} className="EditForm" ref={rootRef}>
      <div className={`EditForm-buttons ${loading ? 'EditForm-buttons-loading' : ''}`}>
        <Button buttonType="ghost" type="button" onClick={onCancel}>Annuleren</Button>
        <Button disabled={loading} type="submit">{(loading || rolesLoading) ? <Spinner width={20} height={20} stroke="var(--white)" /> : buttonName}</Button>
      </div>
      <div>
        <h5 className="EditForm-title" ref={inputRef}>Persoonsgegevens</h5>
        <div className="EditForm-radio-group">
          <RadioInput name="title" value="Mr." label="Dhr" checked={formValues.title === 'Mr.'} onChange={handleInputChange('title')} hasError={missingInput.has('title')} />
          <RadioInput name="title" value="Ms." label="Mevr" checked={formValues.title === 'Ms.'} onChange={handleInputChange('title')} hasError={missingInput.has('title')} />
        </div>
        <div className="EditForm-input-group">
          <Input autoFocus={true} label="Voornaam" value={formValues.firstname} onChange={handleInputChange('firstname')} hasError={missingInput.has('firstname')} />
          <Input label="Naam" value={formValues.surname} onChange={handleInputChange('surname')} hasError={missingInput.has('surname')} onBlur={verifySurnameChange} />
          <Input label="E-mail" value={formValues.email} onChange={handleInputChange('email')} hasError={missingInput.has('email')} type="text" errorMessage="De vorm van het e-mailadres is niet correct." />
          <div></div>
          <Input label="Stamboeknummer" optional={true} value={formValues.personnelNumber} onChange={handleInputChange('personnelNumber')} hasError={missingInput.has('personnelNumber')} errorMessage="Ongeldig stamboeknummer" />
        </div>
        <h5 ref={rolesRef} className="EditForm-title">Functie(s){missingRole && <div className="EditForm-role-error"><Icon name="warning" fill="var(--red)" /><p>Kies een functie!</p></div>}</h5>
        <p className="EditForm-subtitle">Educatie</p>
        <div className="EditForm-checkboxes">
          { createCheckbox('a0d24000000TibmAAC') }
          { createCheckbox('a0d24000000TiboAAC') }
          { createCheckbox('a0d24000000TibhAAC') }
          { createCheckbox('a0d2400000EcRW0AAN') }
        </div>
        <div className="EditForm-segments">
          { createSegment('a0d24000000TibmAAC') }
          { createSegment('a0d24000000TiboAAC') }
        </div>
        <p className="EditForm-subtitle">Beleid & organisatie</p>
        <div className="EditForm-checkboxes">
          { createCheckbox('a0d24000000TibVAAS') }
          { createCheckbox('a0d24000000TibpAAC') }
          { createCheckbox('a0d24000000TibfAAC') }
          { createCheckbox('a0d24000000TibbAAC') }
          { createCheckbox('a0d24000000TibMAAS') }
          { createCheckbox('a0d24000000TEGSAA4') }
          { createCheckbox('a0d24000000TibUAAS') }
          { createCheckbox('a0d24000000TibTAAS') }
        </div>
        <p className="EditForm-subtitle">Zorg</p>
        <div className="EditForm-checkboxes">
          { createCheckbox('a0d24000000TibQAAS') }
          { createCheckbox('a0d24000000TibnAAC') }
          { createCheckbox('a0d24000000TibRAAS') }
          { createCheckbox('a0d24000000TibiAAC') }
          { createCheckbox('a0d24000000TiblAAC') }
        </div>
        <div className="EditForm-segments">
          { createSegment('a0d24000000TiblAAC') }
        </div>
        <p className="EditForm-subtitle">Andere</p>
        <div className="EditForm-checkboxes">
          { createCheckbox('a0d24000000TibXAAS') }
        </div>
        {missingRole && <div className="EditForm-role-error"><Icon name="warning" fill="var(--red)" /><p>Kies een functie!</p></div>}
      </div>
      <div className={`EditForm-buttons ${loading ? 'EditForm-buttons-loading' : ''}`}>
        <Button buttonType="ghost" type="button" onClick={onCancel}>Annuleren</Button>
        <Button disabled={loading} type="submit">{(loading || rolesLoading) ? <Spinner width={20} height={20} stroke="var(--white)" /> : buttonName}</Button>
      </div>
      <Modal show={confirmSurnameModal} onClose={() => handleConfirmSurname(false)} name="confirm">
        <div className="EditForm-confirm-surname">
          <div className="EditForm-confirm-surname-head">
            <button onClick={() => handleConfirmSurname(false)} className="EditForm-confirm-surname-close modal-close-button"><Icon name="cross" /></button>
          </div>
          <h5>Ben je zeker dat je de achternaam van deze persoon wilt aanpassen?</h5>
          <div className="EditForm-buttons">
            <Button buttonType="ghost" type="button" onClick={() => handleConfirmSurname(false)}>Annuleren</Button>
            <Button type="button" onClick={() => handleConfirmSurname(true)}>Ja</Button>
          </div>
        </div>
      </Modal>
    </form>
  )
}

export default EditForm

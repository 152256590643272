import PropTypes from 'prop-types'

const icons = {
  add: props => (
    <svg width="1em" height="1em" viewBox="0 0 18.65 18.65" {...props}>
      <path d="M11.518 0H7.13v7.13L0 7.136v3.973l7.13.409v7.13h4.388l.34-7.13h6.791V7.131l-7.4.006L11.518 0z"/>
    </svg>
  ),
  subtract: props => (
    <svg width="1em" height="1em" viewBox="0 0 11.578 2.724" {...props}>
      <path d="M0 .004V2.47l11.578.254V0z"/>
    </svg>
  ),
  check: props => (
    <svg width={22} height={22} viewBox="0 0 22 22 " {...props}>
      <path
        d="M-.016 10.755l3.354-3.354 5.407 5.844L18.962 2.913l3.04 3.034-9.715 10.403-3.614 3.614-8.69-9.209z"/>
      />
    </svg>
  ),
  delete: props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
      <path d="M16.432 2.014v.914a.457.457 0 01-.457.457H2.257a.457.457 0 01-.457-.457v-.914a.457.457 0 01.457-.457h4.115V0s5.511.008 5.487 0v1.557h4.115a.457.457 0 01.458.457zM3.51 16.317a1.829 1.829 0 001.829 1.7h7.573a1.829 1.829 0 001.829-1.7l.777-11.1h-12.8l.792 11.1z"/>
    </svg>
  ),
  download: props => (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <path d="M13.044 12.125l-2.19 2.192-2.034-2.034-3.306-3.3L7.55 6.95l1.88 2.028.084-9.076h3.106l-.16 8.747 1.7-1.7 2.035 2.034-3.151 3.143z"/>
      <path d="M2.688 17.966h16.438v4.063H2.688z"/>
    </svg>
  ),
  email: props => (
    <svg width="1em" height="1em" viewBox="0 0 17.2 17.2" {...props}>
      <path d="M1.291 1.938a1.127 1.127 0 00-.477.11L8.2 9.562a.535.535 0 00.834 0L16.4 2.048a1.127 1.127 0 00-.477-.11H1.291zm-1.278 1.3A1.766 1.766 0 000 3.45v10.082a1.736 1.736 0 00.1.584l5.111-5.593L.013 3.238zm17.2-.092l-5.2 5.377 5.1 5.6a1.737 1.737 0 00.1-.591l.014-10.173a1.767 1.767 0 00-.013-.213h-.001zm-6.18 6.37l-1.157 1.181a1.728 1.728 0 01-2.528 0L6.191 9.524l-5.034 5.514a1.22 1.22 0 00.134.008h14.633a1.09 1.09 0 00.128-.008l-5.019-5.522z"/>
    </svg>
  ),
  external: props => (
    <svg width="1em" height="1em" viewBox="0 0 14.3 14.3" {...props}>
      <path d="M1.875 3.157v8.438a.469.469 0 00.47.469h8.437a.469.469 0 00.47-.47V6.439l1.874 1.875v3.75a1.873 1.873 0 01-1.876 1.875H1.875A1.875 1.875 0 010 12.063V2.688A1.876 1.876 0 011.875.813h3.75L7.5 2.688H2.344a.469.469 0 00-.469.469z"/>
      <path d="M12.158.256L14.39.25l-.005 2.073-.008 3.37-2.075.004.08-1.993L7.705 8.3l-1.58-1.58 4.55-4.388-1.731.006.005-2.074 3.21-.007z"/>
    </svg>
  ),
  right: props => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path d="M11.144 4.037l2.785 2.807-2.582 2.605-4.2 4.231-2.582-2.6 2.563-2.398H.001V4.703l6.752.095-2.179-2.194L7.156 0l3.988 4.038z"/>
    </svg>
  ),
  logOut: props => (
    <svg width="1em" height="1em" viewBox="0 0 21.5 21.5" {...props}>
      <path d="M0 21.33V.13h8.11v4L3.655 3.9V17.47l4.455.232v3.62H3.655v.007H0zm9.244-8.266l-2.192-2.19 2.033-2.035 3.3-3.306 2.035 2.035-2.029 1.882 9.075.083v3.107l-8.746-.161 1.7 1.7-2.035 2.035-3.141-3.15z"/>
    </svg>
  ),
  print: props => (
    <svg width="1em" height="1em" viewBox="0 0 18.4 18.4" {...props}>
      <path d="M18.41 11.114v-7.28a1.085 1.085 0 00-.943-1.047 3.376 3.376 0 00-.469-.01h-1.325v2.5a.441.441 0 01-.434.434h-.02c-.488.01-.979 0-1.467 0H3.172a.441.441 0 01-.434-.434v-2.5h-1.22a1.75 1.75 0 00-1.026.174 1.135 1.135 0 00-.489.985v8.343a1.233 1.233 0 00.191.755 1.114 1.114 0 00.931.467h2.073c.486-.01.974 0 1.46 0H17.361a1.091 1.091 0 001.048-1.096v-1.292zm-7.885.431h-2.64a.651.651 0 010-1.3h2.64a.651.651 0 110 1.3z"/>
      <path d="M14.801 1.895V4.84h-11.2V.55.516a.665.665 0 01.016-.069L3.63.405A.173.173 0 003.64.37c.017-.03.045-.069.063-.1A.315.315 0 003.73.24c.007-.007.03-.033.052-.054A.523.523 0 003.83.152c.024-.016.05-.028.074-.043a.044.044 0 00.01-.007c.036-.01.074-.024.113-.033a.012.012 0 00.007 0A.558.558 0 004.12.062h8.917a.11.11 0 01.03.01.6.6 0 01.076.016l.013.01a.523.523 0 01.061.032l.065.042c.017.016.035.03.052.048l.013.013c.425.421.851.846 1.272 1.274a.089.089 0 01.016.016l.036.04a.174.174 0 01.022.03l.06.1.01.035c0 .007.012.043.018.071v.017a.266.266 0 01.008.07l.012.01zM14.805 17.727a.742.742 0 000 .076.054.054 0 010 .022.904.904 0 01-.024.1c-.009.017-.017.035-.026.05-.017.03-.036.058-.054.086-.007.007-.01.016-.017.022s-.039.043-.065.07l-.017.01-.048.03c-.024.015-.049.025-.074.038s-.03.01-.045.016c-.035.01-.074.013-.11.024H4.077c-.035-.009-.069-.016-.1-.026 0 0-.017-.007-.03-.009s-.059-.03-.087-.048-.026-.018-.042-.027c-.026-.026-.054-.05-.078-.076a.153.153 0 00-.017-.017c-.019-.033-.042-.063-.059-.1l-.013-.028c-.01-.036-.024-.074-.033-.113v-.03-3.428h10.13c.35 0 .7.009 1.055.009v1.4c0 .649.01 1.3 0 1.949h.002z"/>
    </svg>
  ),
  search: props => (
    <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
      <path d="M14.958 12.874l-4.11-3.7a5.914 5.914 0 10-1.671 1.673l3.938 3.938 1.843-1.911zM1.675 5.909a4.24 4.24 0 114.239 4.24 4.239 4.239 0 01-4.24-4.24z"/>
    </svg>
  ),
  shop: props => (
    <svg width="1em" height="1em" viewBox="0 0 23 23" {...props}>
      <path d="M10.857 19.43a1.45 1.45 0 11-1.45-1.45 1.45 1.45 0 011.45 1.45M18.347 19.43a1.45 1.45 0 11-1.45-1.45 1.45 1.45 0 011.45 1.45M.063 1.813H4.52l.867 2.332h17.449l-2.56 9.726H7.639l.623 1.451 10.8.135v1.62l-12.123.248L3.25 3.659H.063V1.813z"/>
    </svg>
  ),
  warning: props => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path d="M13.498 13.509a7.784 7.784 0 10-5.5 2.273 7.749 7.749 0 005.5-2.273zm-4.446-2.5a1.049 1.049 0 00-1.05-1.051 1.061 1.061 0 00-1.07 1.05 1.073 1.073 0 001.07 1.066 1.061 1.061 0 001.05-1.065zm-1.05-2.117a.741.741 0 01-.71-.71l-.36-3.167a1.082 1.082 0 011.07-1.065 1.061 1.061 0 011.05 1.065l-.34 3.167a.741.741 0 01-.71.71z"
            fillRule="evenodd"/>
    </svg>
  ),
  caretLeft: props => (
    <svg width="1em" height="1em" viewBox="0 0 16.5 16.5" {...props}>
      <path d="M13.31 3.154L10.155 0l-8.28 8.28 8.28 8.281 3.155-3.154L7.987 8.09l5.323-4.937z"/>
    </svg>
  ),
  caretRight: props => (
    <svg width="1em" height="1em" viewBox="0 0 16.5 16.5" {...props}>
      <path d="M2.689 13.47l3.154 3.154 8.28-8.28L5.844.062 2.688 3.217l5.324 5.315L2.69 13.47z"/>
    </svg>
  ),
  caretDown: props => (
    <svg width="1em" height="1em" viewBox="0 0 16.5 16.5" {...props}>
      <path d="M3.092 2.939L-.063 6.093l8.28 8.28 8.282-8.28-3.155-3.155L8.03 8.262 3.092 2.94z"/>
    </svg>
  ),
  caretUp: props => (
    <svg width="1em" height="1em" viewBox="0 0 16.5 16.5" {...props}>
      <path d="M13.344 13.685L16.5 10.53l-8.28-8.28-8.281 8.28 3.154 3.155 5.315-5.323 4.937 5.323z"/>
    </svg>
  ),
  cross: props => (
    <svg width="1em" height="1em" viewBox="0 0 17.5 17.5" {...props}>
      <path d="M17.272 3.335L13.978.04 8.625 5.393 3.267.045.285 3.028l5.045 5.66-5.353 5.353 3.295 3.294 5.608-5.097 5.098 5.098 3.295-3.295-5.56-5.55 5.56-5.156z"/>
    </svg>
  ),
  caretSmall: props => (
    <svg width="1em" height="1em" viewBox="0 0 8 8" {...props}>
      <path d="M1,0 l0,8 6,-4 z"/>
    </svg>
  ),
  pencil: props => (
    <svg width="1em" height="1em" viewBox="0 0 18 18.003" {...props}>
      <path d="M13.177 0a.628.628 0 00-.436.186l-1.968 1.967 5.075 5.075 1.969-1.97a.628.628 0 000-.887L13.632.186A.63.63 0 0013.177 0zM9.884 3.047L.199 12.726a.628.628 0 00-.18.441l-.018 4.2a.633.633 0 00.185.448.626.626 0 00.447.184l4.2-.019a.631.631 0 00.441-.186l9.685-9.677z"/>
    </svg>
  ),
  agenda: props => (
    <svg width="1em" height="1em" viewBox="0 0 323.22 466.64" {...props}>
      <g>
        <path d="M179.72,319.28c2.54-8.57,6.99-16.84,13.34-23.19l46.37-48.9V21c0-6.99-5.4-12.38-12.38-12.38H24.69 c-6.99,0-12.38,5.72-12.38,12.38v396.13c0,20.02,16.21,36.53,36.21,36.53h178.52c6.99,0,12.38-5.72,12.38-12.38 c0-6.68-5.4-12.38-12.38-12.38H48.51c-6.35,0-11.44-5.09-11.44-11.44c0-6.35,5.09-11.44,11.44-11.44h178.52 c6.99,0,12.38-5.4,12.38-12.38l0.01-34.94c-3.5,2.22-7.31,4.13-11.44,5.72l-23.18,8.58c-2.85,0.96-5.72,1.59-8.57,1.59 c-7.62,0-14.93-3.5-19.69-9.85c-4.76-6.35-6.03-13.97-3.81-21.6L179.72,319.28z M159.32,132.09H97.46 c-13.38,0-22.37-8.78-22.37-19.74c0-9.17,6.46-18.17,15.79-19.74c0-15.79,14.48-22.37,25.01-17.11 c3.95-7.9,12.44-14.48,23.69-14.48c14.67,0,25.41,11.78,26.49,24.68c9.79,2.91,16.94,11.97,16.94,22.7 C183.01,121.49,172.4,132.09,159.32,132.09z"/>
        <path d="M309.95,239.88l-10.8-10.16c-5.09-4.76-13.34-4.44-18.1,0.63l-63.53,67.67l29.86,27.63l63.53-67.67 C315.35,252.9,315.35,244.65,309.95,239.88L309.95,239.88z"/>
        <path d="M198.46,355.51l23.19-8.57c4.13-1.59,7.94-4.13,11.44-6.99l-28.91-27c-2.54,3.81-5.09,7.62-6.35,12.07l-7,23.5 C189.56,352.96,194.01,357.09,198.46,355.51z"/>
      </g>
    </svg>
  )
}

const Icon = ({ name, ...props }) => {
  const IconComponent = icons[name] || null
  if (IconComponent) return <IconComponent {...props} />
  return IconComponent
}

Icon.propTypes = {
  name: PropTypes.oneOf(['add', 'check', 'delete', 'download', 'email', 'external', 'right', 'logOut', 'print', 'search', 'shop', 'warning', 'caretLeft', 'caretRight', 'cross', 'subtract', 'caretDown', 'caretUp', 'caretSmall', 'pencil', 'agenda'])
}

export default Icon

import getRoleRequirements from './getRoleRequirements'

const rolesWithSegments = new Set(['a0d24000000TibmAAC', 'a0d24000000TiboAAC', 'a0d24000000TiblAAC'])

function validateRoles (roles) {
  const result = {
    hasAtLeastOneRole: true,
    rolesWithErrors: new Set()
  }

  if (!roles.size) {
    result.hasAtLeastOneRole = false
  }

  const yearNames = ['YEAR1PRESENT__C', 'YEAR2PRESENT__C', 'YEAR3PRESENT__C', 'YEAR4PRESENT__C', 'YEAR5PRESENT__C', 'YEAR6PRESENT__C']
  for (const [key, value] of roles) {
    const roleRequirements = getRoleRequirements(key)
    if (rolesWithSegments.has(key)) {
      if (roleRequirements.has('SEGMENT') && !value.segments.length) {
        result.rolesWithErrors.add(key)
        continue
      }
      for (const segment of value.segments) {
        const noYearsSelected = !yearNames.some(name => segment[name] === '1')
        if (roleRequirements.has(segment.SEGMENT__C) && noYearsSelected) {
          result.rolesWithErrors.add(key)
          break
        }
      }
    }
  }

  return result
}

export default validateRoles

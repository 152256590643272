import { useEffect, useRef } from 'react'
import './Modal.scss'

const updateScrollBarWidthValue = (() => {
  let previousWidth = 0
  return (width) => {
    if (width === previousWidth) return
    previousWidth = width

    const innerHTML = `
      :root {
        --scroll-bar-width: ${width}px;
      }
    `

    const existingStyleTag = document.getElementById('scroll-bar-width')
    if (existingStyleTag) {
      existingStyleTag.innerHTML = innerHTML
    } else {
      const newStyleTag = document.createElement('style')
      newStyleTag.id = 'scroll-bar-width'
      newStyleTag.innerHTML = innerHTML
      document.head.append(newStyleTag)
    }
  }
})()

const Modal = ({ show, onClose, children }) => {
  const rootRef = useRef(null)

  useEffect(() => {
    if (show) {
      let previousTopModal
      if (window.topModal) {
        previousTopModal = window.topModal
      }
      window.topModal = rootRef.current
      return () => {
        if (previousTopModal) {
          window.topModal = previousTopModal
        }
      }
    }
  }, [show])

  useEffect(() => {
    if (show) {
      const windowWidth = window.innerWidth
      const contentWidth = document.body.clientWidth
      const scrollBarWidth = windowWidth - contentWidth
      updateScrollBarWidthValue(scrollBarWidth)
    }
  }, [show])

  useEffect(() => {
    if (show && !document.body.classList.contains('modal-open')) {
      document.body.classList.add('modal-open')
    } else if (!show && document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open')
    }
  }, [show])

  useEffect(() => {
    let mouseClickTarget = null

    if (!show) return
    function handleMousedown (e) {
      if (window.topModal !== rootRef.current) return

      if (e.target.classList.contains('Modal-dialog')) {
        mouseClickTarget = e.target
      }
    }
    function handleMouseup (e) {
      if (mouseClickTarget === e.target) {
        onClose()
      }
    }
    function handleKey (e) {
      if (window.topModal !== rootRef.current) return

      if (e.key === 'Escape') {
        onClose()
      }
      mouseClickTarget = null
    }
    window.addEventListener('mousedown', handleMousedown)
    window.addEventListener('mouseup', handleMouseup)
    window.addEventListener('keyup', handleKey)
    return () => {
      window.removeEventListener('mousedown', handleMousedown)
      window.removeEventListener('mouseup', handleMouseup)
      window.removeEventListener('keyup', handleKey)
    }
  }, [show, onClose])

  if (!show) return null

  return (
    <div className="Modal" ref={rootRef}>
      <div className="Modal-dialog">
        <div className="Modal-content">
          {children}
        </div>
        <div className="Modal-margin"></div>
      </div>
    </div>
  )
}

export default Modal

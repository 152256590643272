import { useState, useEffect, useRef } from 'react'
import './Nav.scss'
import Button from '../../../../components/Button/Button.js'
import Icon from '../../../../components/Icon/Icon.js'

const Nav = ({ surnameLetters = new Set(), isLocked, handleCreateContactModalOpen }) => {
  const roofRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    function handleScroll () {
      if (!roofRef.current) return
      const pos = roofRef.current.getBoundingClientRect()
      if (pos.top <= 9 && !isSticky) {
        setIsSticky(true)
      } else if (pos.top > 9 && isSticky) {
        setIsSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isSticky])

  return (
    <div ref={roofRef} className={`root ${isSticky ? 'sticky' : ''}`}>
      <div className="sticky-center">
        <div className="Nav">
          <div className="buttons">
            <div className="large">
              <NavButton anchorRef="#team">
                <Icon width={16} height={16} name="caretUp" />Boven
              </NavButton>
            </div>
            <div className="alphabet">
              <NavButton anchorRef="#a" disabled={!surnameLetters.has('a')}>A</NavButton>
              <NavButton anchorRef="#b" disabled={!surnameLetters.has('b')}>B</NavButton>
              <NavButton anchorRef="#c" disabled={!surnameLetters.has('c')}>C</NavButton>
              <NavButton anchorRef="#d" disabled={!surnameLetters.has('d')}>D</NavButton>
              <NavButton anchorRef="#e" disabled={!surnameLetters.has('e')}>E</NavButton>
              <NavButton anchorRef="#f" disabled={!surnameLetters.has('f')}>F</NavButton>
              <NavButton anchorRef="#g" disabled={!surnameLetters.has('g')}>G</NavButton>
              <NavButton anchorRef="#h" disabled={!surnameLetters.has('h')}>H</NavButton>
              <NavButton anchorRef="#i" disabled={!surnameLetters.has('i')}>I</NavButton>
              <NavButton anchorRef="#j" disabled={!surnameLetters.has('j')}>J</NavButton>
              <NavButton anchorRef="#k" disabled={!surnameLetters.has('k')}>K</NavButton>
              <NavButton anchorRef="#l" disabled={!surnameLetters.has('l')}>L</NavButton>
              <NavButton anchorRef="#m" disabled={!surnameLetters.has('m')}>M</NavButton>
              <NavButton anchorRef="#n" disabled={!surnameLetters.has('n')}>N</NavButton>
              <NavButton anchorRef="#o" disabled={!surnameLetters.has('o')}>O</NavButton>
              <NavButton anchorRef="#p" disabled={!surnameLetters.has('p')}>P</NavButton>
              <NavButton anchorRef="#q" disabled={!surnameLetters.has('q')}>Q</NavButton>
              <NavButton anchorRef="#r" disabled={!surnameLetters.has('r')}>R</NavButton>
              <NavButton anchorRef="#s" disabled={!surnameLetters.has('s')}>S</NavButton>
              <NavButton anchorRef="#t" disabled={!surnameLetters.has('t')}>T</NavButton>
              <NavButton anchorRef="#u" disabled={!surnameLetters.has('u')}>U</NavButton>
              <NavButton anchorRef="#v" disabled={!surnameLetters.has('v')}>V</NavButton>
              <NavButton anchorRef="#w" disabled={!surnameLetters.has('w')}>W</NavButton>
              <NavButton anchorRef="#x" disabled={!surnameLetters.has('x')}>X</NavButton>
              <NavButton anchorRef="#y" disabled={!surnameLetters.has('y')}>Y</NavButton>
              <NavButton anchorRef="#z" disabled={!surnameLetters.has('z')}>Z</NavButton>
            </div>
            <div className="large"><NavButton anchorRef={'#' + [...surnameLetters][surnameLetters.size - 1]}><Icon width={16} height={16} name="caretDown" />Onder</NavButton></div>
          </div>
          { !isLocked && <div className="add-button"><Button onClick={handleCreateContactModalOpen}><Icon name="add" width={12} height={12} /><p>Persoon toevoegen</p></Button></div> }
        </div>
      </div>
    </div>
  )
}

const NavButton = ({ children, disabled, onClick = () => {}, anchorRef = '' }) => {
  return (
    <a className="nav-button" href={anchorRef}>
      <button onClick={onClick} disabled={disabled}>{children}</button>
    </a>
  )
}

export default Nav

const segments = {
  LO: 'Lager onderwijs',
  KO: 'Kleuteronderwijs',
  BLO: 'Buitengewoon lager onderwijs',
  BKO: 'Buitengewoon kleuteronderwijs'
}

const segmentColors = {
  LO: {
    primary: '#EE7732',
    secondary: '#EE773233'
  },
  BLO: {
    primary: '#EE7732',
    secondary: '#EE773233'
  },
  KO: {
    primary: '#118AB2',
    secondary: '#118AB21a'
  },
  BKO: {
    primary: '#118AB2',
    secondary: '#118AB21a'
  }
}

const yearNames = {
  LO: {
    YEAR1PRESENT__C: '1',
    YEAR2PRESENT__C: '2',
    YEAR3PRESENT__C: '3',
    YEAR4PRESENT__C: '4',
    YEAR5PRESENT__C: '5',
    YEAR6PRESENT__C: '6'
  },
  BLO: {
    YEAR1PRESENT__C: '1',
    YEAR2PRESENT__C: '2',
    YEAR3PRESENT__C: '3',
    YEAR4PRESENT__C: '4',
    YEAR5PRESENT__C: '5',
    YEAR6PRESENT__C: '6'
  },
  KO: {
    YEAR1PRESENT__C: '2,5 jr',
    YEAR2PRESENT__C: '3 jr',
    YEAR3PRESENT__C: '4 jr',
    YEAR4PRESENT__C: '5 jr'
  },
  BKO: {
    YEAR1PRESENT__C: '2,5 jr',
    YEAR2PRESENT__C: '3 jr',
    YEAR3PRESENT__C: '4 jr',
    YEAR4PRESENT__C: '5 jr'
  }
}

const yearTitle = {
  LO: 'Leerjaar',
  BLO: 'Leerjaar',
  KO: 'Leeftijd',
  BKO: 'Leeftijd'
}

function getSegmentTranslation (segmentCode) {
  return segments[segmentCode] || ''
}

function getSegmentColors (segmentCode) {
  return segmentColors[segmentCode] || {}
}

function getSegmentYears (segment) {
  const keyNames = ['YEAR1PRESENT__C', 'YEAR2PRESENT__C', 'YEAR3PRESENT__C', 'YEAR4PRESENT__C', 'YEAR5PRESENT__C', 'YEAR6PRESENT__C']
  const matches = keyNames.filter(key => parseInt(segment[key]))
  return matches.map(key => yearNames[segment.SEGMENTCODE__C][key])
}

function getSegmentYearTitle (segmentCode) {
  return yearTitle[segmentCode] || ''
}

export default getSegmentTranslation
export { getSegmentColors, getSegmentYears, yearNames, getSegmentYearTitle }

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function updateInformation (update) {
  return handleRequest(
    getApiHost() + '/accounts/updateInformation',
    {
      method: 'PUT',
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      json: update
    }
  ).json()
}

export default updateInformation

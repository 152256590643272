import { useState } from 'react'
import './Contacts.scss'
import Icon from '../../../components/Icon/Icon.js'
import getSegmentTranslation, { getSegmentColors, getSegmentYears, getSegmentYearTitle } from '../../../utils/getSegmentTranslation.js'
import Nav from './Nav/Nav.js'
import Modal from '../../../components/Modal/Modal.js'
import DeleteContactForm from '../DeleteContactForm/DeleteContactForm.js'
import useHandleErrors from '../../../hooks/useHandleErrors.js'
import useRoles from '../../../hooks/useRoles.js'
import Spinner from '../../../components/Spinner/Spinner.js'

const rolesWithSegments = new Set(['a0d24000000TibmAAC', 'a0d24000000TiboAAC', 'a0d24000000TiblAAC'])

const Contacts = ({ formattedContacts = [], surnameLetters = new Set(), removeContact, showEditModal, isLocked, handleCreateContactModalOpen }) => {
  const availableRefs = new Set([...surnameLetters])
  const [deleteModalContact, setDeleteModalContact] = useState(null)

  function getRef (surname) {
    const letter = ((surname || '')[0] || '').toLowerCase()
    if (availableRefs.has(letter)) {
      availableRefs.delete(letter)
      return letter
    }
    return ''
  }

  function showDeleteModal (formattedContact) {
    return () => setDeleteModalContact(formattedContact)
  }

  return (
    <div className="Contacts">
      <Modal show={!!deleteModalContact} onClose={showDeleteModal(null)}>
        <DeleteContactForm
          formattedContact={deleteModalContact}
          closeModal={showDeleteModal(null)}
          removeContact={removeContact}
        />
      </Modal>
      { formattedContacts.length > 20 && <Nav surnameLetters={surnameLetters} isLocked={isLocked} handleCreateContactModalOpen={handleCreateContactModalOpen} /> }
      { formattedContacts.map(formattedContact => (
          <Contact
            key={formattedContact.contact._id}
            formattedContact={formattedContact}
            anchorRef={getRef(formattedContact.contact.LASTNAME)}
            showDeleteModal={showDeleteModal(formattedContact)}
            showEditModal={showEditModal(formattedContact)}
            isLocked={isLocked}
          />
      ))
      }
    </div>
  )
}

const Contact = ({ formattedContact = {}, anchorRef = '', showDeleteModal, showEditModal, isLocked }) => {
  const fullName = [formattedContact.contact.FIRSTNAME, formattedContact.contact.LASTNAME].join(' ').trim()
  const groupedRoles = formattedContact.groupedRoles
  const isValid = formattedContact.isValid
  const classes = ['Contact']
  if (!isValid) classes.push('Contact-error')
  return (
    <div className={classes.join(' ')}>
      <div className="anchor-target" id={anchorRef}></div>
      <div className="info">
        <h4>{fullName}</h4>
        <p>{formattedContact.contact.EMAIL || ''}</p>
        <p>{formattedContact.contact.PERSONNEL_NUMBER__C || ''}</p>
        { !isValid && <p className="Contact-error-message">De gegevens van deze persoon zijn nog niet helemaal correct!</p> }
      </div>
      <div className="roles">
        { [...groupedRoles].map(([ROLE__C, groupedRole]) => <Role key={ROLE__C} groupedRole={groupedRole} />) }
      </div>
      { !isLocked && <div className="buttons">
        <button className="edit" onClick={showEditModal}><Icon name="pencil" width={18} height={18} fill="var(--blue-link)" />Aanpassen</button>
        <button className="delete" onClick={showDeleteModal}><Icon name="delete" width={21} height={21} fill="var(--red)" />Persoon verwijderen</button>
      </div> }
    </div>
  )
}

const Role = ({ groupedRole: { role, segments } = {} }) => {
  const [rolesError, rolesLoading, , rolesMap] = useRoles([])

  useHandleErrors([rolesError])

  const showSegments = rolesWithSegments.has(role.ROLE__C)
  const roleName = (rolesMap.get(role.ROLE__C) || {}).Frontend
  return (
    <div className="Role">
      <Icon name="caretSmall" fill="var(--blue-dark)" width={8} height={8} />
      { rolesLoading ? <Spinner width={20} height={20} stroke="var(--white)" /> : <p>{roleName || ''}</p> }
      { showSegments && segments.map(segment => <Segment key={segment._id} segment={segment} />) }
    </div>
  )
}

const Segment = ({ segment = {} }) => {
  const segmentCode = segment.SEGMENTCODE__C
  const segmentName = getSegmentTranslation(segmentCode)
  const segmentColors = getSegmentColors(segmentCode)
  const segmentYears = getSegmentYears(segment)
  const segmentYearTitle = getSegmentYearTitle(segmentCode)
  return (
    <div className="Role-Segment" style={{ backgroundColor: segmentColors.secondary }}>
      <p style={{ color: segmentColors.primary }}>{segmentName} {segmentYears.length ? segmentYearTitle.toLowerCase() : null} {segmentYears.join(', ')}</p>
    </div>
  )
}

export { Role }
export default Contacts

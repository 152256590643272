import ky from 'ky'

function handleRequest (url, options = {}) {
  if (isNaN(options.timeout)) {
    options.timeout = 45000
  }
  return ky(url, options)
}

export default handleRequest

import { useState, useEffect, useMemo } from 'react'
import api from '../api/api.js'
import handleSessionExpired from '../utils/handleSessionExpired.js'

let rolesCache = null
let fetching = false

const deferRequests = (() => {
  let callbacks = []
  return {
    add: item => {
      callbacks.push(item)
    },
    end: value => {
      callbacks.forEach(fn => fn(value))
      callbacks = []
    }
  }
})()

function useRoles (initialRoles) {
  const [roles, setRoles] = useState(initialRoles)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const rolesMap = useMemo(() => {
    if (Array.isArray(roles) && roles.length) {
      return new Map(roles.map(role => [role.ID, role]))
    }
    return new Map()
  }, [roles])

  useEffect(() => {
    let isUnmounting = false

    if (rolesCache) {
      setRoles(rolesCache)
      return
    }

    async function getAccount () {
      if (fetching) {
        return deferRequests.add(value => {
          if (!isUnmounting) {
            setRoles(value)
          }
        })
      } else {
        fetching = true
      }

      setLoading(true)
      setError(null)
      let roles
      try {
        roles = await api.contacts.getRoles()
        if (isUnmounting) return
        if (roles.success) {
          rolesCache = roles.data
          return setRoles(roles.data)
        }
        return setError(roles)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return handleSessionExpired()
        }
        if (isUnmounting) return
        return setError(error)
      } finally {
        fetching = false
        deferRequests.end(roles && roles.data)
        if (!isUnmounting) setLoading(false)
      }
    }
    getAccount()

    return () => { isUnmounting = true }
  }, [])

  return [error, loading, roles, rolesMap]
}

export default useRoles

import './Header.scss'
import Button from '../Button/Button.js'
import Icon from '../Icon/Icon.js'
import setLoggedOut from '../../utils/setLoggedOut.js'

const Header = () => {
  const isLoggedIn = window.localStorage.getItem('token') // TODO

  function handleLogout () {
    setLoggedOut()
  }

  return (
    <div className="Header">
      <img alt="logo" src="/images/logo.png" />
      { isLoggedIn &&
        <div className="logout">
          <div>
            <Button buttonType="ghost" onClick={handleLogout}><Icon name="logOut" width={22} height={22} /> Uitloggen</Button>
          </div>
        </div>
      }
    </div>
  )
}

export default Header

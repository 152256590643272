import { useRef, useMemo } from 'react'
import './Checkbox.scss'
import PropTypes from 'prop-types'
import { ErrorMessage } from '../Input/Input.js'
import Icon from '../Icon/Icon.js'

const Checkbox = ({ checked, onChange, id, hasError, label, disabled }) => {
  const inputRef = useRef(null)

  function handleClick (e) {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  function handleKeyPress (e) {
    if (e.code === 'Space') {
      e.preventDefault()
      inputRef.current.click()
    }
  }

  const classes = ['Checkbox']
  if (hasError) classes.push('error')
  if (checked) classes.push('checked')

  const wrapperClasses = ['Checkbox-wrapper']
  if (disabled) wrapperClasses.push('Checkbox-disabled')

  return (
    <div className={wrapperClasses.join(' ')} tabIndex="0" onKeyPress={handleKeyPress}>
      <div className={classes.join(' ')} onClick={handleClick}><Icon name="check" fill="var(--white)" width={22} height={22} /></div>
      { label && <label htmlFor={id} className="Checkbox-label">{label}</label> }
      <input ref={inputRef} type="checkbox" id={id} checked={checked} onChange={onChange} disabled={disabled} />
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.any,
  onChange: PropTypes.func,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

const CheckboxContainer = ({ checked, onChange, errorMessage, hasError, label, id, disabled }) => {
  id = useMemo(() => { return id || (Math.round(Math.random() * 10000000)).toString() }, [id])

  return (
    <div className="Checkbox-root">
      <Checkbox checked={checked} onChange={onChange} id={id} hasError={hasError} label={label} disabled={disabled} />
      { errorMessage && <ErrorMessage errorMessage={errorMessage} hasError={hasError} /> }
    </div>
  )
}

CheckboxContainer.propTypes = {
  checked: PropTypes.any,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool
}

export { Checkbox, ErrorMessage }
export default CheckboxContainer

import './DateInput.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import nlbe from 'date-fns/locale/nl-BE'
registerLocale('nl-BE', nlbe)
setDefaultLocale('nl-BE')

const dateFormat = new Intl.DateTimeFormat('nl-be')

const DateInput = ({ value, onChange, minDate = false, maxDate = false }) => {
  const formattedDate = Object.prototype.toString.apply(value).slice(8, -1) === 'Date' ? dateFormat.format(value) : ''

  return (
    <div className="DateInput-root">
      <DatePicker
        selected={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <div className="DateInput">
            <p>{formattedDate}</p>
            <Icon name="caretDown" width={10} height={10} fill="var(--blue-dark)" />
          </div>
        }
      />
    </div>
  )
}

DateInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
}

export default DateInput

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function getAdminInformation (email) {
  return handleRequest(
    getApiHost() + '/accounts/getAdminInformation',
    {
      headers: {
        'admin-email': window.localStorage.getItem('adminEmail') || '',
        authorization: window.localStorage.getItem('adminToken') || ''
      }
    }
  ).json()
}

export default getAdminInformation

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function openSchool (body) {
  return handleRequest(
    getApiHost() + '/accounts/openSchool',
    {
      method: 'POST',
      headers: {
        'admin-email': window.localStorage.getItem('adminEmail') || '',
        authorization: window.localStorage.getItem('adminToken') || ''
      },
      json: body
    }
  ).json()
}

export default openSchool

import { useEffect } from 'react'
import './ConfirmSubmissionSuccess.scss'
import history from '../../history.js'

const ConfirmSubmissionSuccess = () => {
  let totalDiaries = 0
  try {
    totalDiaries = window.history.state.usr.totalDiaries
  } catch (error) {}

  useEffect(() => {
    setTimeout(() => {
      history.push('/')
    }, 5000)
  }, [])

  return (
    <div className="ConfirmSubmissionSuccess">
      <img id="logo" alt="van in logo" src="/images/logo.png" />
      <div className="ConfirmSubmissionSuccess-box">
        <h3>Bedankt!</h3>
        <hr />
        <h4>De schoolteamgegevens zijn ingediend.</h4>
        { !!totalDiaries && <p>De {totalDiaries === 1 ? 'agenda wordt' : totalDiaries + ' agenda’s worden'} uitgestuurd vanaf april 2025!</p> }
      </div>
    </div>
  )
}

export default ConfirmSubmissionSuccess

import React from 'react'
import isDev from '../../utils/isDev.js'
import Notifier from '../Notifier/Notifier.js'

const DevTools = isDev && React.lazy(() => import('../DevTools/DevTools.js'))

const App = ({ children }) => (
  <div className="App">
    {children}
    { isDev && <React.Suspense fallback={null}><DevTools /></React.Suspense> }
    <Notifier />
  </div>
)

export default App

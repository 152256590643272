import { useState } from 'react'
import './SubmitModal.scss'
import Icon from '../../../components/Icon/Icon.js'
import Button from '../../../components/Button/Button.js'
import api from '../../../api/api.js'
import { createErrorNotification } from '../../../components/Notifier/Notifier.js'
import history from '../../../history.js'
import Spinner from '../../../components/Spinner/Spinner.js'

const SubmitModal = ({ closeModal, totalDiaries }) => {
  const [loading, setLoading] = useState(false)

  async function handleSubmit () {
    if (loading) return
    try {
      setLoading(true)
      const response = await api.accounts.submitAndLockAccount({
        totalDiaries
      })
      if (response.success) {
        return history.push('/confirmSubmissionSuccess#', {
          totalDiaries
        })
      }
    } catch (error) {
      return createErrorNotification(error)
    }
  }

  return (
    <div className="SubmitModal">
      <div className="SubmitModal-head">
        <h3>Gegevens indienen?</h3>
        <button onClick={closeModal} className="SubmitModal-close modal-close-button"><Icon name="cross" /></button>
      </div>
      <div>
        <p>Dit kan niet ongedaan gemaakt worden en je kunt nadien geen wijzigingen meer doorvoeren.</p>
      </div>
      <div className="SubmitModal-buttons">
        <Button buttonType="ghost" onClick={closeModal}>Annuleren</Button>
        <Button onClick={handleSubmit}>{ loading ? <Spinner width={20} height={20} stroke="var(--white)" /> : 'Ja, indienen' }</Button>
      </div>
    </div>
  )
}

export default SubmitModal

import Checkbox from '../../../components/Checkbox/Checkbox.js'
import './Segments.scss'
import Icon from '../../../components/Icon/Icon.js'
import getSegmentTranslation from '../../../utils/getSegmentTranslation.js'
import getRoleRequirements from '../../../utils/getRoleRequirements.js'

const Segment = ({ active, onChange, onYearChange, value, roleCode, roleName, hasError }) => {
  const roleRequirements = getRoleRequirements(roleCode)
  if (!active) return null
  const segmentsSet = new Set(value.segments.map(({ SEGMENTCODE__C }) => SEGMENTCODE__C))
  const segmentsMap = new Map(value.segments.map(segment => [segment.SEGMENTCODE__C, segment]))
  const isSportsTeacher = roleCode === 'a0d24000000TiboAAC'
  return (
    <div className="Segment" id={`segment-${value.role.ROLE__C || value.role.ID}`}>
      <p className={`Segment-title ${roleRequirements.has('SEGMENT') ? 'required' : ''}`}>{`${roleName} in:`}</p>
      <div className="Segments-list">
        <Checkbox hasError={hasError && !segmentsSet.size}
                  label="Lager onderwijs"
                  checked={segmentsSet.has('LO')}
                  onChange={onChange('LO')}/>
        <YearRow hasError={hasError}
                 required={roleRequirements.has('LO')}
                 active={!!segmentsMap.get('LO') && !isSportsTeacher}
                 onChange={onYearChange('LO')}
                 currentValues={segmentsMap.get('LO')}/>
        <Checkbox hasError={hasError && !segmentsSet.size}
                  label="Kleuteronderwijs"
                  checked={segmentsSet.has('KO')}
                  onChange={onChange('KO')}/>
        <AgeRow hasError={hasError}
                required={roleRequirements.has('KO')}
                active={!!segmentsMap.get('KO') && !isSportsTeacher}
                onChange={onYearChange('KO')}
                currentValues={segmentsMap.get('KO')}/>
        <Checkbox hasError={hasError && !segmentsSet.size}
                  label={getSegmentTranslation('BLO')}
                  checked={segmentsSet.has('BLO')}
                  onChange={onChange('BLO')}/>
        <YearRow hasError={hasError}
                 required={roleRequirements.has('BLO')}
                 active={!!segmentsMap.get('BLO') && !isSportsTeacher}
                 onChange={onYearChange('BLO')}
                 currentValues={segmentsMap.get('BLO')}/>
        <Checkbox hasError={hasError && !segmentsSet.size}
                  label={getSegmentTranslation('BKO')}
                  checked={segmentsSet.has('BKO')}
                  onChange={onChange('BKO')}/>
        <AgeRow hasError={hasError}
                required={roleRequirements.has('BKO')}
                active={!!segmentsMap.get('BKO') && !isSportsTeacher}
                onChange={onYearChange('BKO')}
                currentValues={segmentsMap.get('BKO')}/>
      </div>
    </div>
  )
}

const YearRow = ({ active, onChange, currentValues, hasError, required }) => {
  if (!active) return null
  const showError = hasError && required && !['YEAR1PRESENT__C', 'YEAR2PRESENT__C', 'YEAR3PRESENT__C', 'YEAR4PRESENT__C', 'YEAR5PRESENT__C', 'YEAR6PRESENT__C'].some(name => currentValues[name] === '1')
  return (<>
    <div className="Segment-years">
      <p className={required ? 'required' : ''}>Leerjaar:</p>
      <Checkbox hasError={showError} checked={currentValues.YEAR1PRESENT__C === '1'} onChange={onChange('YEAR1PRESENT__C')} label="1" />
      <Checkbox hasError={showError} checked={currentValues.YEAR2PRESENT__C === '1'} onChange={onChange('YEAR2PRESENT__C')} label="2" />
      <Checkbox hasError={showError} checked={currentValues.YEAR3PRESENT__C === '1'} onChange={onChange('YEAR3PRESENT__C')} label="3" />
      <Checkbox hasError={showError} checked={currentValues.YEAR4PRESENT__C === '1'} onChange={onChange('YEAR4PRESENT__C')} label="4" />
      <Checkbox hasError={showError} checked={currentValues.YEAR5PRESENT__C === '1'} onChange={onChange('YEAR5PRESENT__C')} label="5" />
      <Checkbox hasError={showError} checked={currentValues.YEAR6PRESENT__C === '1'} onChange={onChange('YEAR6PRESENT__C')} label="6" />
    </div>
    {showError && <div className="Segment-years-error"><Icon name="warning" fill="var(--red)" /><p>Kies het jaar</p></div>}
  </>)
}

const AgeRow = ({ active, onChange, currentValues, hasError, required }) => {
  if (!active) return null
  const showError = hasError && required && !['YEAR1PRESENT__C', 'YEAR2PRESENT__C', 'YEAR3PRESENT__C', 'YEAR4PRESENT__C'].some(name => currentValues[name] === '1')
  return (<>
    <div className="Segment-years">
      <p className={required ? 'required' : ''}>Leeftijd:</p>
      <Checkbox hasError={showError} checked={currentValues.YEAR1PRESENT__C === '1'} onChange={onChange('YEAR1PRESENT__C')} label="2,5 jr" />
      <Checkbox hasError={showError} checked={currentValues.YEAR2PRESENT__C === '1'} onChange={onChange('YEAR2PRESENT__C')} label="3 jr" />
      <Checkbox hasError={showError} checked={currentValues.YEAR3PRESENT__C === '1'} onChange={onChange('YEAR3PRESENT__C')} label="4 jr" />
      <Checkbox hasError={showError} checked={currentValues.YEAR4PRESENT__C === '1'} onChange={onChange('YEAR4PRESENT__C')} label="5 jr" />
    </div>
    {showError && <div className="Segment-years-error"><Icon name="warning" fill="var(--red)" /><p>Kies de leeftijd</p></div>}
  </>)
}

export default Segment

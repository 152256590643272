import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function downloadUnsubmittedAccountsData () {
  return handleRequest(
    getApiHost() + '/exports/downloadUnsubmittedAccountsData',
    {
      method: 'GET',
      headers: {
        authorization: window.localStorage.getItem('adminToken') || '',
        'admin-email': window.localStorage.getItem('adminEmail') || ''
      }
    }
  )
}

export default downloadUnsubmittedAccountsData

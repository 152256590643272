function getApiHost () {
  if (process.env.REACT_APP_API_URL) return process.env.REACT_APP_API_URL
  const host = window.location.hostname
  if (host === 'localhost') {
    return 'https://api.schoolteamgegevens.localhost.dev'
  } else if (host === 'staging.schoolteamgegevens.viavanin.be') {
    return 'https://staging.api.schoolteamgegevens.viavanin.be'
  }
  return 'https://api.schoolteamgegevens.viavanin.be'
}

console.log('getApiHost: ', getApiHost())

export default getApiHost

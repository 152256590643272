import login from './authentication/login.js'
import adminLogin from './authentication/adminLogin.js'

import getInformation from './accounts/getInformation.js'
import updateInformation from './accounts/updateInformation.js'
import submitAndLockAccount from './accounts/submitAndLockAccount.js'
import getAdminInformation from './accounts/getAdminInformation.js'
import openSchool from './accounts/openSchool.js'

import getContacts from './contacts/getContacts.js'
import getRoles from './contacts/getRoles.js'
import createContact from './contacts/createContact.js'
import getContact from './contacts/getContact.js'
import updateContact from './contacts/updateContact.js'
import severContact from './contacts/severContact.js'

import downloadSubmittedAccountsData from './exports/downloadSubmittedAccountsData.js'
import downloadUnsubmittedAccountsData from './exports/downloadUnsubmittedAccountsData.js'
import importData from './exports/importData.js'
import availableExports from './exports/availableExports.js'
import downloadExport from './exports/downloadExport.js'

const api = {
  accounts: {
    getInformation,
    updateInformation,
    submitAndLockAccount,
    getAdminInformation,
    openSchool
  },
  authentication: {
    login,
    adminLogin
  },
  contacts: {
    getContacts,
    getRoles,
    getContact,
    createContact,
    updateContact,
    severContact
  },
  exports: {
    downloadSubmittedAccountsData,
    downloadUnsubmittedAccountsData,
    importData,
    availableExports,
    downloadExport
  }
}

export default api

import setLoggedOut from './setLoggedOut.js'
import { createNotification } from '../components/Notifier/Notifier.js'

function handleSessionExpired () {
  if (window.localStorage.getItem('token')) {
    createNotification({
      message: 'Sessie is vervallen',
      type: 'error'
    })
  }
  setLoggedOut()
}

export default handleSessionExpired

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function getContact (contactObjectID) {
  return handleRequest(
    getApiHost() + '/contacts/getContact',
    {
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      searchParams: {
        contactObjectID
      }
    }
  ).json()
}

export default getContact

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

async function login ({ username, password }) {
  try {
    const response = await handleRequest(
      getApiHost() + '/authentication/login',
      {
        method: 'post',
        json: {
          BUSINESSPARTNERID__C: username,
          PASSWORD: password
        }
      }
    ).json()
    return response
  } catch (error) {
    try {
      const body = await error.response.json()
      return body
    } catch (e) {}
    throw error
  }
}

function getSession () {
  return ''
}

export { getSession }
export default login

import React from 'react'
import Login from './pages/Login/Login.js'
import Home from './pages/Home/Home.js'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js'
import isDev from './utils/isDev.js'
import ConfirmSubmissionSuccess from './pages/ConfirmSubmissionSuccess/ConfirmSubmissionSuccess.js'
import PrintContacts from './pages/PrintContacts/PrintContacts.js'
import AdminTools from './pages/Admin/AdminTools/AdminTools.js'
import AdminLogin from './pages/Admin/AdminLogin/AdminLogin.js'

const routes = [
  {
    path: '/',
    action: () => <Home />
  },
  {
    path: '/login',
    action: () => <Login />
  },
  {
    path: '/privacy-policy',
    action: () => <PrivacyPolicy />
  },
  {
    path: '/confirmSubmissionSuccess',
    action: () => <ConfirmSubmissionSuccess />
  },
  {
    path: '/print',
    action: () => <PrintContacts />
  },
  {
    path: '/admin/login',
    action: () => <AdminLogin />
  },
  {
    path: '/admin',
    action: () => <AdminTools />
  },
  isDev && {
    path: '/previews',
    action: async () => {
      const Previews = React.lazy(() => import('./pages/Previews/Previews.js'))
      return <React.Suspense fallback={<div>React.Suspense in action!</div>}>
        <Previews />
      </React.Suspense>
    }
  }
]

export default routes

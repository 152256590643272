import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function downloadExport ({ exportObjectId }) {
  return handleRequest(
    getApiHost() + '/exports/downloadExport',
    {
      method: 'GET',
      searchParams: {
        exportObjectId
      },
      headers: {
        authorization: window.localStorage.getItem('adminToken') || '',
        'admin-email': window.localStorage.getItem('adminEmail') || ''
      }
    }
  )
}

export default downloadExport

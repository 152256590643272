import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function getInformation () {
  return handleRequest(
    getApiHost() + '/accounts/getInformation',
    {
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      }
    }
  ).json()
}

export default getInformation

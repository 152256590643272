import { useEffect, useRef } from 'react'
import { createErrorNotification } from '../components/Notifier/Notifier.js'

function useHandleErrors (errors) {
  const previousErrors = useRef([])
  useEffect(() => {
    const previousErr = previousErrors.current
    const newErrors = errors.reduce((accumulator, currentValue, index) => {
      if (currentValue && !previousErr[index]) {
        return [...accumulator, currentValue]
      }
      return accumulator
    }, [])
    previousErrors.current = errors

    if (newErrors.length) {
      newErrors.forEach(error => {
        createErrorNotification(error)
      })
    }
  }, [errors])
}

export default useHandleErrors

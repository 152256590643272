import useContacts from '../../hooks/useContacts.js'
import { Role } from '../Home/Contacts/Contacts.js'
import './PrintContacts.scss'
import Button from '../../components/Button/Button.js'

const PrintContacts = () => {
  const [, , contacts] = useContacts([])

  function handlePrintClick () {
    window.print && window.print()
  }

  return (
    <div className="PrintContacts">
      <div className="PrintContacts-header">
        <Button onClick={handlePrintClick}>Printen</Button>
      </div>
      { contacts.map(contact => <Contact key={contact.contact._id} formattedContact={contact} />) }
    </div>
  )
}

const Contact = ({ formattedContact }) => {
  const fullName = [formattedContact.contact.FIRSTNAME, formattedContact.contact.LASTNAME].join(' ').trim()
  const groupedRoles = formattedContact.groupedRoles

  return (
    <div className="PrintContact">
      <div className="PrintContact-info">
        <div className="PrintContact-info-left">
          <h4>{fullName}</h4>
          <p>{formattedContact.contact.EMAIL || ''}</p>
          <p>{formattedContact.contact.PERSONNEL_NUMBER__C || ''}</p>
        </div>
        <div className="PrintContact-info-right">
          { [...groupedRoles].map(([ROLE__C, groupedRole]) => <Role key={ROLE__C} groupedRole={groupedRole} />) }
        </div>
      </div>
    </div>
  )
}

export default PrintContacts

import setAdminLoggedOut from './setAdminLoggedOut.js'
import { createNotification } from '../components/Notifier/Notifier.js'

function handleAdminSessionExpired () {
  if (window.localStorage.getItem('adminToken')) {
    createNotification({
      message: 'Sessie is vervallen',
      type: 'error'
    })
  }
  setAdminLoggedOut()
}

export default handleAdminSessionExpired

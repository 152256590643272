import Header from '../../components/Header/Header.js'
import Footer from '../../components/Footer/Footer.js'
import './PrivacyPolicy.scss'
import Icon from '../../components/Icon/Icon.js'
import history from '../../history.js'

const PrivacyPolicy = () => {
  const isLoggedIn = window.localStorage.getItem('token') // TODO

  function handleBackClick () {
    if (window.history && window.history.length > 1) {
      history.back()
    } else {
      history.push('/')
    }
  }

  return (
    <div className="PrivacyPolicy">
      <Header />
      <div className="center">
        <div className="content">
          <button onClick={handleBackClick}>
            <div><Icon name="caretLeft" width={16} height={16} /></div><div>{ isLoggedIn ? 'Terug naar overzicht' : 'Terug naar login' }</div>
          </button>
          <h3>Privacy policy</h3>
          <hr />
          <div className="text">
            <p>We verzamelen de gegevens van jouw schoolteam om informatie te kunnen delen die nodig is in het kader van de functie. Elke persoon kan op elk moment zijn/haar persoonlijke voorkeuren voor het ontvangen van e-mailcommunicatie aanpassen. Lees de volledige privacy policy op&nbsp;</p>
            <a target="_blank" rel="noreferrer noopener" href="https://www.vanin.be/nl/privacy">https://www.vanin.be/nl/privacy<Icon name="external" width={15} height={15} /></a>
          </div>
        </div>
      </div>
      { isLoggedIn && <Footer /> }
    </div>
  )
}

export default PrivacyPolicy

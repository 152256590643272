import Icon from '../Icon/Icon.js'
import './Footer.scss'
import history from '../../history.js'

const Footer = () => {
  function handlePolicyClick () {
    history.push('/privacy-policy#')
  }

  return (
    <div className="Footer">
      <div className="button-container">
        <a target="_blank" className="button" href="/print"><Icon name="print" width={18} height={18} /> Printen</a>
        <button className="button" onClick={handlePolicyClick}>Privacy Policy</button>
        <a target="_blank" rel="noopener noreferrer" className="button" href="https://get2.adobe.com/nl/reader/">Acrobat reader <Icon name="external" width={15} height={15} /></a>
      </div>

      <div className="text-container">
        <p>Heb je een vraag?</p>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
          <span style={{ color: 'white' }}>Neem contact op via het </span>{' '}
          <a href="https://www.vanin.be/lager-onderwijs/contact/" target="_blank" rel="noreferrer">contactformulier.</a></div>
      </div>
    </div>
  )
}

export default Footer

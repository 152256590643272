import { useState, useEffect } from 'react'
import api from '../api/api.js'
import handleAdminSessionExpired from '../utils/handleAdminSessionExpired.js'

function useExports (initialExports = []) {
  const [availableExports, setAvailableExports] = useState(initialExports)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [reloadState, forceReload] = useState(false)

  function reload () {
    forceReload(!reloadState)
  }

  useEffect(() => {
    let isUnmounting = false

    async function getAccount () {
      setLoading(true)
      setError(null)
      try {
        const availableExports = await api.exports.availableExports().json()
        if (isUnmounting) return
        if (availableExports.success) {
          return setAvailableExports(availableExports.exports)
        }
        return setError(availableExports)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return handleAdminSessionExpired()
        }
        if (isUnmounting) return
        return setError(error)
      } finally {
        if (!isUnmounting) setLoading(false)
      }
    }
    getAccount()

    return () => { isUnmounting = true }
  }, [reloadState])

  return [error, loading, availableExports, reload]
}

export default useExports

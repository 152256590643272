import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function severContact (contactObjectID) {
  return handleRequest(
    getApiHost() + '/contacts/severContact',
    {
      method: 'DELETE',
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      searchParams: {
        contactObjectID
      }
    }
  ).json()
}

export default severContact

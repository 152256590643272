import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

async function adminLogin ({ email, password }) {
  try {
    const response = await handleRequest(
      getApiHost() + '/authentication/adminLogin',
      {
        method: 'post',
        json: {
          email,
          password
        }
      }
    ).json()
    return response
  } catch (error) {
    try {
      const body = await error.response.json()
      return body
    } catch (e) {}
    throw error
  }
}

export default adminLogin

import { useState } from 'react'
import Button from '../../../components/Button/Button.js'
import Input from '../../../components/Input/Input.js'
import './AdminLogin.scss'
import setAdminLoggedIn from '../../../utils/setAdminLoggedIn.js'
import api from '../../../api/api.js'
import useHandleErrors from '../../../hooks/useHandleErrors.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import history from '../../../history.js'
import useHasUnmounted from '../../../hooks/useHasUnmounted.js'

const AdminLogin = () => {
  const [loginState, setLoginState] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({
    email: false,
    password: false
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const hasUnmounted = useHasUnmounted(false)

  useHandleErrors([error])

  function handleLoginInputChange (name) {
    return e => {
      setLoginState({
        ...loginState,
        [name]: e.target.value
      })
      setErrors({
        ...errors,
        [name]: false
      })
    }
  }

  async function handleLoginSubmit (e) {
    e.preventDefault()
    setError(null)
    if (loginState.email && loginState.password) {
      setLoading(true)
      let token
      try {
        const result = await api.authentication.adminLogin({
          email: loginState.email,
          password: loginState.password
        })
        await new Promise((resolve, reject) => {
          setTimeout(resolve, 2000)
        })
        token = result.token
        if (!result.success) {
          if (result.error === 'adminNotFoundOrInvalidPassword') {
            if (!hasUnmounted.current) {
              setError(result)
              return setErrors({
                email: true,
                password: true
              })
            }
          }
        }
      } catch (error) {
        if (!hasUnmounted.current) {
          return setError(error)
        }
      } finally {
        if (!hasUnmounted.current) {
          setLoading(false)
        }
      }
      if (!hasUnmounted.current) {
        setAdminLoggedIn(token, loginState.email)
        return history.push('/admin')
      }
    }
    if (!hasUnmounted.current) {
      setErrors({
        email: !loginState.email,
        password: !loginState.password
      })
    }
  }

  return (
    <div className="AdminLogin">
      <form onSubmit={handleLoginSubmit} className="AdminLogin-form">
        <img id="logo" alt="van in logo" src="/images/logo.png" />
        <h3 className="AdminLogin-title">Schoolteamgegevens basisschool</h3>
        <Input hasError={!!errors.email} value={loginState.email} label="E-mailadres" onChange={handleLoginInputChange('email')} />
        <Input hasError={!!errors.password} value={loginState.password} label="Wachtwoord" type="password" onChange={handleLoginInputChange('password')} />
        <Button disabled={loading} className="AdminLogin-button" type="submit">{ loading ? <Spinner width={24} height={24} stroke="var(--white)" /> : 'Log in'}</Button>
      </form>
    </div>
  )
}

export default AdminLogin

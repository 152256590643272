import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function getContacts ({ page = 0, pageSize = 100 }) {
  return handleRequest(
    getApiHost() + '/contacts/getContacts',
    {
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      searchParams: {
        page,
        pageSize
      },
      timeout: 30000 // default: 10000
    }
  ).json()
}

export default getContacts

import Spinner from '../../components/Spinner/Spinner.js'

const Loading = () => {
  return (
    <div className="Loading">
      <Spinner/> <p>een ogenblikje a.u.b</p>
    </div>
  )
}

export default Loading

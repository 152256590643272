import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function createContact (contact) {
  return handleRequest(
    getApiHost() + '/contacts/createContact',
    {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      json: contact
    }
  ).json()
}

export default createContact

import UniversalRouter from 'universal-router'
import routes from './routes.js'
import history from './history.js'
import React from 'react'
import * as ReactDOM from 'react-dom'
import App from './components/App/App.js'
import NotFound from './pages/NotFound/NotFound.js'
import ErrorPage from './pages/ErrorPage/ErrorPage.js'

const root = document.getElementById('root')

const options = {
  errorHandler (error, context) {
    console.error(error)
    console.info(context)
    if (error.status === 404) {
      return <NotFound />
    } else {
      return <ErrorPage />
    }
  }
}
const router = new UniversalRouter(routes, options)

function render (location) {
  const destination = location.pathname

  const hasToken = !!window.localStorage.getItem('token')
  const hasAdminToken = !!window.localStorage.getItem('adminToken')

  const noAuthDestinations = new Set(['/login', '/login/', '/admin/login', '/admin/login/'])
  const anyAuthDestinations = new Set(['/privacy-policy'])
  if (!hasToken && (!noAuthDestinations.has(destination) && !anyAuthDestinations.has(destination))) {
    if (destination === '/admin' || destination === '/admin/') {
      if (!hasAdminToken) {
        return history.push('/admin/login')
      }
    } else {
      return history.push('/login')
    }
  } else if (hasToken && noAuthDestinations.has(destination)) {
    if (destination !== '/admin/login' && destination !== '/admin/login/') {
      return history.push('/')
    }
  } else if (!hasAdminToken && (destination === '/admin' || destination === '/admin/')) {
    return history.push('/admin/login')
  }

  router.resolve({
    pathname: destination,
    context: {}
  }).then(component => {
    if (component.redirect) {
      history.push(component.redirect)
    } else {
      ReactDOM.render(
        <React.StrictMode>
          <App>{component}</App>
        </React.StrictMode>,
        root
      )
    }
  })
}

// Listen for changes to the current location.
// const unlisten =
history.listen(({ action, location }) => {
  render(location)
})

// To stop listening, call the function returned from listen().
// unlisten()

// Render the app for the first time
async function initialize () {
  // verify token
  // or just straight up try to renew it
  // if success -> continue
  // else goto login

  // let response
  // try {
  //   // validate token
  //   response = await validate(token)
  // } catch (error) {
  //   if (error.response && error.response.status === 401) {
  //     removeToken()
  //     history.push('/login')
  //     response = response || {}
  //   } else {
  //     return alert(error.toString())
  //   }
  // }

  // if (response.ok) {
  //   try {
  //     await initializeUser()
  //   } catch (error) {
  //     console.warn('couldn\'t get user\n', error)
  //     window.alert('invalid token')
  //     removeToken()
  //     history.push('/login')
  //   }
  // // } else if (response.status === 401) {
  // } else if (!response.ok) {
  //   removeToken()
  //   history.push('/login')
  // } else {
  //   console.warn('unexpected response status', response)
  //   console.warn('token', token)
  //   removeToken()
  //   history.push('/login')
  // }

  render(history.location)
}

initialize()

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function submitAndLockAccount (body) {
  return handleRequest(
    getApiHost() + '/accounts/submitAndLockAccount',
    {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem('token') || '',
        'business-partner-id': window.localStorage.getItem('businessPartnerId') || ''
      },
      json: body
    }
  ).json()
}

export default submitAndLockAccount

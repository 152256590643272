const getRoleRequirements = (roleCode) => {
  const defaultRequirements = new Set(['SEGMENT', 'LO', 'KO', 'BLO', 'BKO'])

  // Leerkracht
  if (roleCode === 'a0d24000000TibmAAC') {
    return new Set(['SEGMENT', 'LO', 'KO'])
  }

  // Leerkracht Lichamelijke Opvoeding
  if (roleCode === 'a0d24000000TiboAAC') {
    return new Set([])
  }

  // Zorgleerkracht
  if (roleCode === 'a0d24000000TiblAAC') {
    return new Set(['SEGMENT'])
  }

  return defaultRequirements
}

export default getRoleRequirements

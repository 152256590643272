import { useState, useEffect } from 'react'
import api from '../api/api.js'
import handleAdminSessionExpired from '../utils/handleAdminSessionExpired.js'

let adminInfoCache = null

function useAdminInfo (initialAdminInfo) {
  const [adminInfo, setAdminInfo] = useState(initialAdminInfo)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let isUnmounting = false

    if (adminInfoCache) {
      setAdminInfo(adminInfoCache)
      return
    }

    async function getAccount () {
      setLoading(true)
      setError(null)
      try {
        const adminInfo = await api.accounts.getAdminInformation()
        if (isUnmounting) return
        if (adminInfo.success) {
          adminInfoCache = adminInfo.information
          return setAdminInfo(adminInfo.information)
        }
        return setError(adminInfo)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return handleAdminSessionExpired()
        }
        if (isUnmounting) return
        return setError(error)
      } finally {
        if (!isUnmounting) setLoading(false)
      }
    }
    getAccount()

    return () => { isUnmounting = true }
  }, [])

  return [error, loading, adminInfo]
}

export default useAdminInfo

import { useMemo, useCallback, useEffect, useState } from 'react'
import './Input.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'

const Input = ({ value, onChange, id, hasError, autoFocus, ...props }) => {
  const [inputEl, setInputEl] = useState(null)
  const inputRef = useCallback(node => {
    if (node) {
      setInputEl(node)
    }
  }, [])
  useEffect(() => {
    if (autoFocus && inputEl) {
      inputEl.focus()
    }
  }, [autoFocus, inputEl])
  const classes = ['Input']
  if (hasError) classes.push('error')
  return <input id={id} ref={inputRef} className={classes.join(' ')} value={value} onChange={onChange} {...props} />
}

Input.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool
}

const Label = ({ label, id, optional }) => {
  const className = `Input-label ${optional ? 'optional' : 'required'}`
  return <label htmlFor={id} className={className}>{label}{ optional && <p className="Input-label-optional">Optioneel</p> }</label>
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  id: PropTypes.string
}

const ErrorMessage = ({ hasError, errorMessage }) => {
  if (!hasError) return null
  return (
    <div className="Input-error">
      <Icon name="warning" fill="var(--red)" width={16} height={16} />
      <p>{errorMessage}</p>
    </div>
  )
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  hasError: PropTypes.bool
}

const InputContainer = ({ value, onChange, errorMessage, hasError, label, id, optional = false, autoFocus, ...props }) => {
  id = useMemo(() => { return id || (Math.round(Math.random() * 10000000)).toString() }, [id])

  return (
    <div className="Input-root">
      { label && <Label label={label} id={id} optional={optional} /> }
      <Input value={value} onChange={onChange} id={id} hasError={hasError} autoFocus={autoFocus} {...props} />
      { errorMessage && <ErrorMessage errorMessage={errorMessage} hasError={hasError} /> }
    </div>
  )
}

InputContainer.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  autoFocus: PropTypes.bool
}

export { Input, Label, ErrorMessage }
export default InputContainer

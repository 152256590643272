import { useState, useEffect } from 'react'
import api from '../api/api.js'
import handleSessionExpired from '../utils/handleSessionExpired.js'

function useAccount (initialAccount) {
  const [account, setAccount] = useState(initialAccount)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [forceReloadValue, forceReload] = useState(false)

  function reload () {
    forceReload(!forceReloadValue)
  }

  useEffect(() => {
    let isUnmounting = false

    async function getAccount () {
      setLoading(true)
      setError(null)
      try {
        const account = await api.accounts.getInformation()
        if (isUnmounting) return
        if (account.success) {
          return setAccount(account.information)
        }
        return setError(account)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return handleSessionExpired()
        }
        if (isUnmounting) return
        return setError(error)
      } finally {
        if (!isUnmounting) setLoading(false)
      }
    }
    getAccount()

    return () => { isUnmounting = true }
  }, [forceReloadValue])

  return [error, loading, account, reload]
}

export default useAccount

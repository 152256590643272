import { useState, useEffect, useRef, useCallback } from 'react'
import './Notifier.scss'
import Icon from '../Icon/Icon.js'

const getId = (() => {
  let id = 0
  return () => {
    return id++
  }
})()

const Notifier = () => {
  const [notifications, _setNotifications] = useState(new Set())
  const notificationsRef = useRef(notifications)

  const getNotifications = () => new Set(notificationsRef.current)
  const setNotifications = useCallback(newNotifications => {
    _setNotifications(newNotifications)
    notificationsRef.current = newNotifications
  }, [])

  useEffect(() => {
    function handleEvent (e) {
      const notification = {
        id: getId(),
        ...e.detail
      }
      const newNotifications = getNotifications()
      newNotifications.add(notification)
      setNotifications(newNotifications)
    }

    window.addEventListener('createNotification', handleEvent)
    return () => window.removeEventListener('createNotification', handleEvent)
  }, [notifications, setNotifications])

  function onClose (notification) {
    return () => {
      const newNotifications = getNotifications()
      newNotifications.delete(notification)
      setNotifications(newNotifications)
    }
  }

  return (
    <div className="Notifier">
      {[...notifications].map(notification => <Card key={notification.id} {...notification} onClose={onClose(notification)} />)}
    </div>
  )
}

const Card = ({ message, type = 'success', onClose }) => {
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(onClose, 8000)
    }
  }, [onClose])

  let iconName, iconColor
  switch (type) {
    case 'edit': {
      iconName = 'pencil'
      iconColor = 'var(--blue-link)'
      break
    }

    case 'delete': {
      iconName = 'delete'
      iconColor = 'var(--red)'
      break
    }

    case 'error': {
      iconName = 'cross'
      iconColor = 'var(--red)'
      break
    }

    case 'success':
    default: {
      iconName = 'check'
      iconColor = 'var(--green)'
      break
    }
  }

  return (
    <div className="Notifier-card" onClick={onClose}>
      <Icon name={iconName} fill={iconColor} />
      <p>{message}</p>
    </div>
  )
}

function createNotification (notification) {
  /*
    notification = {
      message: string,
      type: oneOf[edit, delete, error, success]
    }
  */
  window.dispatchEvent(new window.CustomEvent('createNotification', { detail: notification }))
}

const errorMessages = {
  writeCSVFailed: 'De export kon niet gemaakt worden: writeCSVFailed.',
  invalidFromDate: 'De \'van\' datum is niet geldig.',
  invalidToDate: 'De \'tot\' datum is niet geldig.',
  fromDateIsNotBeforeToDate: 'De \'van\' datum is niet voor de \'tot\' datum.',
  accountIsLocked: 'De gegevens zijn al ingediend. Aanpassingen kunnen niet meer gemaakt worden.',
  acrRoleNotFound: 'Minstens één van de geselecteerde rollen bestaat niet.',
  contactDoesNotBelongToAccount: 'Deze person hoort niet bij dit account.',
  emailAddressNotUnique: 'Het e-mailadres moet uniek zijn.',
  contactNotFound: 'Dit contact werd niet gevonden.',
  invalidPersonnelNumber: 'Het stamboeknummer is niet geldig.',
  duplicateRoleSegmentCombination: 'Hetzeflde segment kan maar één keer per rol toegevoegd worden.',
  adminNotFoundOrInvalidPassword: 'Het e-mailadres of wachtwoord is ongeldig.',
  invalidAdminEmail: 'Het e-mailadres is ongeldig.',
  jwtNotValid: 'De sessie token is ongeldig. Log opnieuw in.',
  userNotFoundOrInvalidPassword: 'Het klantnummer of de unieke code is ongeldig.',
  invalidBusinessPartnerId: 'De sessie token is ongeldig. Log opnieuw in.',
  tooManyTotalDiaries: 'Er kunnen niet meer schoolagenda’s als het totaal aantal contacten besteld worden.',
  tooFewTotalDiaries: 'Er kunnen niet minder als 0 schoolagenda’s besteld worden.',
  invalidTotalDiaries: 'Het geselecteerde aantal schoolagenda’s is geen geldig getal.',
  accountCouldNotBeLocked: 'De gegevens konden niet met succes ingediend worden.',
  invalidEmail: 'Dit e-mailadres is niet geldig.',
  accountDoesNotExist: 'Deze school kon niet gevonden worden.',
  accountIsAlreadyUnlocked: 'Deze school is al open.',
  accountCouldNotBeReopened: 'Deze school kon niet heropend worden.'
}

async function createErrorNotification (error) {
  let errorInfo
  try {
    errorInfo = await error.response.json()
    if (errorInfo.success === false && errorInfo.error) {
      errorInfo = errorMessages[errorInfo.error] || ('Onverwachte fout: ' + errorInfo.error)
    }
  } catch (jsonError) {
    try {
      if (error.success === false && error.error) {
        errorInfo = errorMessages[error.error] || ('Onverwachte fout: ' + error.error)
      } else {
        errorInfo = error.response ? error.response.statusText || '' : 'Onverwachte fout'
      }
    } catch (e) {
      errorInfo = 'Onverwachte fout'
    }
  }
  return createNotification({
    message: errorInfo,
    type: 'error'
  })
}

export { createNotification, createErrorNotification }
export default Notifier

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function availableExports () {
  return handleRequest(
    getApiHost() + '/exports/getExports',
    {
      method: 'GET',
      headers: {
        authorization: window.localStorage.getItem('adminToken') || '',
        'admin-email': window.localStorage.getItem('adminEmail') || ''
      }
    }
  )
}

export default availableExports

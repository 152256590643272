import './Button.scss'
import PropTypes from 'prop-types'

const Button = ({ children, buttonType = 'default', className = '', ...props }) => {
  const classes = ['Button-root', buttonType]
  if (className) classes.push(className)
  return (
    <div className={classes.join(' ')}>
      <button {...props}>{children}</button>
    </div>
  )
}

Button.propTypes = {
  buttonType: PropTypes.oneOf(['default', 'ghost'])
}

export default Button

import { useState, useEffect } from 'react'
import Icon from '../../../components/Icon/Icon.js'
import './CreateContactForm.scss'
import Button from '../../../components/Button/Button.js'
import Input from '../../../components/Input/Input.js'
import { Role } from '../Contacts/Contacts.js'
import EditForm from './EditForm.js'

const CreateContactForm = ({ closeModal, reloadContact, formattedContacts, formattedContact: initialFormattedContact }) => {
  const [name, setName] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [formattedContact, setFormattedContact] = useState(initialFormattedContact || { contact: {}, groupedRoles: new Map() })
  const [editingExisting, setEditingExisting] = useState(!!initialFormattedContact)
  const [showEditForm, setShowEditForm] = useState(!!initialFormattedContact)

  useEffect(() => {
    if (name.length >= 3) {
      const lowercaseName = name.toLowerCase()
      const matches = formattedContacts.filter(({ contact }) => {
        return ~(contact.LASTNAME || '').toLowerCase().indexOf(lowercaseName)
      })
      setSuggestions(matches)
    } else {
      setSuggestions([])
    }
  }, [name, formattedContacts])

  function handleNameChange (e) {
    setName(e.target.value)
  }

  function handleEditClick (formattedContact) {
    return () => {
      setFormattedContact(formattedContact)
      setEditingExisting(true)
      setShowEditForm(true)
      // show 'edit' form
    }
  }

  function handleAddClick () {
    setShowEditForm(true)
  }

  function handleSubmitSuccess (contactObjectID) {
    closeModal()
    return reloadContact(contactObjectID)
  }

  const addButtonName = suggestions.length ? 'Nee, nieuwe persoon toevoegen' : 'Toevoegen'
  const fullName = editingExisting ? [formattedContact.contact.FIRSTNAME, formattedContact.contact.LASTNAME].join(' ').trim() : ''
  const title = editingExisting ? `${fullName} aanpassen` : 'Persoon toevoegen'

  return (
    <div className="CreateContactForm">
      <div className="CreateContactForm-head">
        <h3>{title}</h3>
        <button onClick={closeModal} className="CreateContactForm-close modal-close-button"><Icon name="cross" /></button>
      </div>
      { showEditForm
        ? <EditForm editingExisting={editingExisting} formattedContact={formattedContact} onCancel={closeModal} handleSubmitSuccess={handleSubmitSuccess} initialSurname={name} />
        : <>
          <div className="CreateContactForm-input">
            <Input label="Achternaam" value={name} onChange={handleNameChange} autoFocus={true} />
          </div>
          {
            !!suggestions.length && <div>
              <hr />
              <h4 className="CreateContactForm-title">Bedoel je misschien:</h4>
              <div className="CreateContactForm-suggestions">
                {suggestions.map(formattedContact => <Suggestion key={formattedContact.contact._id} formattedContact={formattedContact} handleEditClick={handleEditClick(formattedContact)} />)}
              </div>
            </div>
          }
          <div className="CreateContactForm-buttons">
            <Button buttonType="ghost" type="button" onClick={closeModal}>Annuleren</Button>
            <Button onClick={handleAddClick}>{addButtonName}</Button>
          </div>
        </>
      }
    </div>
  )
}

const Suggestion = ({ formattedContact, handleEditClick }) => {
  const fullName = [formattedContact.contact.FIRSTNAME, formattedContact.contact.LASTNAME].join(' ').trim()
  const groupedRoles = formattedContact.groupedRoles

  return (
    <div className="CreateContactForm-suggestion">
      <div className="CreateContactForm-suggestion-info">
        <div className="CreateContactForm-suggestion-info-left">
          <h4>{fullName}</h4>
          <p>{formattedContact.EMAIL}</p>
        </div>
        <div className="CreateContactForm-suggestion-info-right">
          { [...groupedRoles].map(([ROLE__C, groupedRole]) => <Role key={ROLE__C} groupedRole={groupedRole} />) }
        </div>
      </div>
      <Button onClick={handleEditClick}>Ja, aanpassen</Button>
    </div>
  )
}

export default CreateContactForm

import handleRequest from '../../utils/handleRequest.js'
import getApiHost from '../../utils/getApiHost.js'

function importData (formData) {
  return handleRequest(
    getApiHost() + '/exports/importData',
    {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem('adminToken') || '',
        'admin-email': window.localStorage.getItem('adminEmail') || ''
      },
      body: formData
    }
  )
}

export default importData
